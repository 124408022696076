import React, {Component} from 'react';
import {withRouter} from 'next/router';
import {CSSTransition} from 'react-transition-group';
import Link from 'next/link';
import css from 'styled-jsx/css';
import Image from '../../Image';

import {ArrowLeft, ArrowRight} from '../../Svgs/Arrows';

import {
    isSpanishPage,
    alphabetizeArrayByKey,
    getElemOffset,
    extractSlug,
    getPathname,
} from '../../../lib/utils';
import {domainUrl} from '../../../lib/globals';

import {NAV_HEIGHT_MOBILE} from './MobileTopNav';
import {zIndex} from 'aac-components/utils/helpers';

const MAX_WIDTH = '1400px';
const SIDE_NAV_WIDTH = '100%';

const LinkItem = ({link, isNested = false, handleOnClick: click}) => {
    const {href = '', name = '', external = false} = link;
    const linkHref = href.includes(domainUrl) ? getPathname(href) : href;

    const handleOnClick = () => {
        click();
    };

    if (external) {
        return (
            <div>
                <a
                    href={linkHref}
                    title={name}
                    target="_blank"
                    className={isNested ? 'nested' : 'default'}
                    onClick={handleOnClick}>
                    {name}
                </a>
                <style jsx>
                    {`
                        a {
                            display: block;
                            color: var(--primary);
                            font-family: var(--title-font);
                        }
                        a.default {
                            padding: 16px 24px;
                            font-size: 16px;
                            font-weight: normal;
                            letter-spacing: -0.16px;
                            line-height: 24px;
                            text-decoration: none;
                        }
                        a.nested {
                            padding: 5px 0;
                            font-size: 12px;
                            font-weight: normal;
                            letter-spacing: -0.16px;
                            line-height: 16px;
                            text-decoration: none;
                        }
                    `}
                </style>
            </div>
        );
    }

    return (
        <div className="secondary-link">
            <Link href={linkHref} className="section-header" onClick={handleOnClick}>
                <span className={isNested ? 'nested' : 'default'}>{name}</span>
            </Link>

            <style jsx>
                {`
                    .secondary-link :global(.section-header) {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-top: 1px solid var(--gray-200);
                    }
                    .secondary-link :global(a) {
                        display: block;
                        color: var(--primary);
                        font-family: var(--title-font);
                    }
                    .secondary-link :global(span.default) {
                        padding: 16px 24px;
                        font-size: 16px;
                        font-weight: normal;
                        letter-spacing: -0.16px;
                        line-height: 24px;
                        text-decoration: none;
                    }
                    .secondary-link :global(span.nested) {
                        padding: 5px 0;
                        font-size: 12px;
                        font-weight: normal;
                        letter-spacing: -0.16px;
                        line-height: 16px;
                        text-decoration: none;
                    }
                `}
            </style>
        </div>
    );
};

class SideNav extends Component {
    _isMounted = false;

    state = {
        activeSubSection: '',
    };

    componentDidMount = () => {
        this._isMounted = true;
    };
    componentWillUnmount = () => {
        this._isMounted = false;
    };

    toggleSecondaryNav = (sectionTitle) => {
        this.setState({
            activeSubSection: sectionTitle,
        });
    };

    render() {
        const slug = this.props?.['router']?.['query']?.['slug'] ?? '';

        const {
            activeSubName = '',
            toggleSubNav,
            resetNav,
            activeSideNav,
            menu,
        } = this.props;

        const {activeSubSection} = this.state;

        const activeSubLinks = menu.find((item) => {
            const {title = ''} = item;
            const selectedTitle = title && title.replace(/\ /g, '_').toLowerCase();

            return activeSubName === selectedTitle || activeSubName === 'search';
        });

        return (
            <CSSTransition
                in={activeSideNav}
                classNames={'slide'}
                timeout={0}
                appear={true}
                unmountOnExit>
                <nav className="side-nav">
                    <div className="side-nav__menu">
                        <div className="menu-item" onClick={resetNav}>
                            <div className="item" style={{flex: 1}}>
                                <ArrowLeft
                                    className="icon"
                                    width="20px"
                                    height="20px"
                                    fill="var(--primary)"
                                    style={{marginTop: '3px'}}
                                />
                            </div>
                            <div className="item" style={{flex: 11}}>
                                <label
                                    style={{
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        letterSpacing: '0.8px',
                                        textTransform: 'uppercase',
                                    }}>
                                    Close Menu
                                </label>
                            </div>
                        </div>
                        <div className="menu-item">
                            <div className="item" style={{flex: 11, margin: '8px 0'}}>
                                <label
                                    style={{
                                        fontSize: '24px',
                                        lineHeight: '32px',
                                        letterSpacing: '-0.48px',
                                    }}>
                                    Main Menu
                                </label>
                            </div>
                        </div>
                    </div>

                    <ul className="side-nav__list">
                        {menu &&
                            menu.map((item, index) => {
                                const {
                                    title: displayName = '',
                                    child_items: sections = [],
                                    url = '',
                                } = item;

                                const name =
                                    displayName &&
                                    displayName.replace(/\ /g, '_').toLowerCase();

                                const activeName = activeSubName === name ? '' : name;

                                const sectionTitleSlug = extractSlug(url);

                                if (sections) {
                                    return (
                                        <li
                                            key={name}
                                            onClick={() => {
                                                toggleSubNav(activeName);
                                            }}
                                            className={`mobile-link__primary ${name} ${
                                                activeSubName === name ? 'active' : ''
                                            }`}>
                                            <div className="section-header">
                                                <span>{displayName}</span>
                                                <span style={{fontSize: '20px'}}>
                                                    {activeSubName === name ? (
                                                        ''
                                                    ) : (
                                                        <ArrowRight
                                                            className="icon"
                                                            width="20px"
                                                            height="20px"
                                                            fill="var(--primary)"
                                                            style={{marginTop: '3px'}}
                                                        />
                                                    )}
                                                </span>
                                            </div>

                                            <CSSTransition
                                                classNames={'slide'}
                                                timeout={0}
                                                appear={true}
                                                in={activeSubName === name}
                                                unmountOnExit>
                                                <ul
                                                    className={`secondary-nav ${
                                                        activeSubName === name
                                                            ? 'active'
                                                            : ''
                                                    }`}>
                                                    <div className="side-nav__menu">
                                                        <div className="menu-item">
                                                            <div
                                                                className="item"
                                                                style={{flex: 1}}>
                                                                <ArrowLeft
                                                                    className="icon"
                                                                    width="20px"
                                                                    height="20px"
                                                                    fill="var(--primary)"
                                                                    style={{
                                                                        marginTop: '3px',
                                                                    }}
                                                                    onClick={() => {
                                                                        toggleSubNav(
                                                                            activeName,
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                            <div
                                                                className="item"
                                                                style={{flex: 11}}>
                                                                <label
                                                                    style={{
                                                                        fontSize: '12px',
                                                                        lineHeight:
                                                                            '16px',
                                                                        letterSpacing:
                                                                            '0.8px',
                                                                        textTransform:
                                                                            'uppercase',
                                                                    }}>
                                                                    Back to Main Menu
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="menu-item">
                                                            <div
                                                                className="item"
                                                                style={{
                                                                    flex: 11,
                                                                    margin: '8px 0',
                                                                }}>
                                                                <Link
                                                                    href={
                                                                        sectionTitleSlug
                                                                    }
                                                                    title={displayName}
                                                                    className="sub-nav__title"
                                                                    onClick={resetNav}>
                                                                    <label
                                                                        style={{
                                                                            fontSize:
                                                                                '24px',
                                                                            lineHeight:
                                                                                '32px',
                                                                            letterSpacing:
                                                                                '-0.48px',
                                                                        }}>
                                                                        {displayName}
                                                                    </label>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {sections.map((section, i) => {
                                                        const {
                                                            title: sectionTitle = '',
                                                            url: sectionLink = '',
                                                        } = section;

                                                        const sectionTitleSlug =
                                                            extractSlug(sectionLink);

                                                        return (
                                                            <li
                                                                key={sectionTitle}
                                                                className={`mobile-link__primary`}>
                                                                <LinkItem
                                                                    link={{
                                                                        name: sectionTitle,
                                                                        href: sectionLink,
                                                                    }}
                                                                    handleOnClick={
                                                                        resetNav
                                                                    }
                                                                />
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </CSSTransition>
                                        </li>
                                    );
                                }
                            })}
                    </ul>

                    <style jsx global>
                        {`
                            body {
                                overflow: hidden;
                            }
                        `}
                    </style>

                    <style jsx>{`
                        nav {
                            max-width: ${MAX_WIDTH};
                            display: flex;
                            margin: 0 auto;
                        }

                        .side-nav {
                            display: block;
                            max-height: 100%;
                            z-index: ${zIndex('navigation')};
                            position: fixed;
                            margin-top: ${NAV_HEIGHT_MOBILE};
                            right: 0;
                            top: 0;
                            bottom: 88px;
                            height: 100vh;
                            width: ${SIDE_NAV_WIDTH};
                            background: #ffffff;
                            overflow-y: scroll;
                        }
                        .secondary-nav {
                            display: none;
                            max-height: 100%;
                            z-index: ${zIndex('subNavigation')};
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            height: auto;
                            min-height: 462px;
                            width: ${SIDE_NAV_WIDTH};
                            background: #ffffff;
                            overflow-y: scroll;
                        }
                        .secondary-nav.active {
                            display: block;
                        }

                        .side-nav__menu {
                            background: #fff;
                            border-radius: 0px;
                            width: 100%;
                            height: 112px;
                            padding: 24px;
                        }
                        .side-nav__menu .menu-item {
                            display: flex;
                        }
                        .side-nav__menu .menu-item .item {
                        }
                        .side-nav__menu .menu-item .item .icon {
                        }
                        .side-nav__menu .menu-item .item label {
                            color: #1a1a1a;
                            font-family: var(--copy-font);
                            font-weight: bold;
                        }

                        .slide-enter {
                            transform: translateX(100%);
                        }
                        .slide-enter-done {
                            transform: translateX(0px);
                            transition: transform 200ms ease;
                        }
                        .slide-exit {
                            transform: translateX(0px);
                        }
                        .slide-exit-active {
                            transform: translateX(100%);
                            transition: transform 200ms ease;
                        }

                        ul {
                            padding: 0;
                            margin: 0;
                        }
                        li {
                            color: var(--primary);
                            font-size: 16px;
                            font-weight: bold;
                            list-style: none;
                        }

                        .mobile-link__primary span {
                            padding: 16px 24px;
                            color: #1a1a1a;
                            font-family: var(--title-font);
                            font-size: 16px;
                            font-weight: normal;
                            letter-spacing: -0.16px;
                            line-height: 24px;
                        }
                        .section-header {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-top: 1px solid var(--gray-200);
                        }
                        .secondary-nav .mobile-link__primary.sub-section {
                            border-top: 1px solid var(--gray-200);
                        }
                        .sub-section .section-header {
                            display: flex;
                            flex-flow: column;
                            justify-content: space-between;
                            align-items: center;
                            border: 0;
                        }
                        .sub-section .section-header .section-header-title,
                        .sub-section .section-header .section-header-list {
                            width: 100%;
                        }
                        .sub-section .section-header .section-header-title label {
                            color: #1a1a1a;
                            font-family: var(--title-font);
                            font-size: 12px;
                            font-weight: bold;
                            letter-spacing: 0.8px;
                            line-height: 16px;
                            text-transform: uppercase;
                        }
                        .sub-section .section-header .section-header-list {
                            display: block;
                        }
                    `}</style>
                </nav>
            </CSSTransition>
        );
    }
}

export default withRouter(SideNav);
